'use strict';
//Start by defining the main module and adding the module dependencies
angular.module(ApplicationConfiguration.applicationModuleName, ApplicationConfiguration.applicationModuleVendorDependencies);
// Setting HTML5 Location Mode
angular.module(ApplicationConfiguration.applicationModuleName).config(['$locationProvider',
	function ($locationProvider) {
		$locationProvider.hashPrefix('!');
	}
]);

angular.module(ApplicationConfiguration.applicationModuleName).config(['schemaFormDecoratorsProvider', function (decoratorsProvider) {
	decoratorsProvider.addMapping('bootstrapDecorator', 'checkboxes-inline', 'modules/core/decorators/checkboxes-inline.html');
	decoratorsProvider.addMapping('bootstrapDecorator', 'checkbox-custom', 'modules/core/decorators/checkbox-custom.html');
	decoratorsProvider.addMapping('bootstrapDecorator', 'material-input', 'modules/core/decorators/material-input.html');
	decoratorsProvider.addMapping('bootstrapDecorator', 'radios-custom', 'modules/core/decorators/custom-radios.html');
	decoratorsProvider.addMapping('bootstrapDecorator', 'select-custom', 'modules/core/decorators/select-custom.html');
	decoratorsProvider.addMapping('bootstrapDecorator', 'default-custom', 'modules/core/decorators/default-custom.html');
	decoratorsProvider.addMapping('bootstrapDecorator', 'password-custom', 'modules/core/decorators/password-custom.html');
	decoratorsProvider.addMapping('bootstrapDecorator', 'submit', 'modules/core/decorators/submit-custom.html');
	decoratorsProvider.addMapping('bootstrapDecorator', 'button', 'modules/core/decorators/submit-custom.html');
}]);
angular.module(ApplicationConfiguration.applicationModuleName).config(['$translateProvider', function ($translateProvider) {
        $translateProvider.useStaticFilesLoader({
            prefix: 'data/translations/',
            suffix: '.json'
        });
        //to save local language
        // $translateProvider.useLocalStorage();
        $translateProvider.preferredLanguage('en');
        $translateProvider.useSanitizeValueStrategy('sanitizeParameters');
    }]);

//Then define the init function for starting up the application
angular.element(document).ready(function () {
	//Fixing facebook bug with redirect
	if (window.location.hash === '#_=_') window.location.hash = '#!';

	//Then init the app
	angular.bootstrap(document, [ApplicationConfiguration.applicationModuleName], {strictDi: true});
});
